import React from 'react';
import { BsSuitHeart } from "react-icons/bs";

const Main = () => {
    return (
        <div>
            <div className="body-div">
                <div className="name">hi, i'm abby.</div>
                <BsSuitHeart /> 
            </div>
        </div>

    )
}

export default Main;
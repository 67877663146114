import React from 'react';


const AboutMe = () => {
    return (
        <div>
            <div className="aboutme-summary">
                i'm currently a ux/ui engineer at a cloud platform company. but whatever title you want to give me, I just enjoy building cool stuff and collaborating with others who do the same.  
                <br />
                <br />
                in my spare time, you can find me watching horror flicks and teaching my dog new tricks. 
            </div>
        </div>
    )
}

export default AboutMe;